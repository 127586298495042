import React from "react"

import Header from "../../layouts/Header.En"
import Footer from "../../layouts/Footer.En"

import Button from "../../components/Button"
import SEO from "../../components/SEO"

import Astronaut from "../../images/astronaut-illustration.svg"
import Businessman from "../../images/businessman-illustration.svg"

export const IndexPage = () => (
  <div className="flex flex-col min-h-screen font-sans text-gray-900">
    <SEO
      keywords={[`kopernicus`, `support`, `specialist`]}
      title="Front Page"
    />

    <Header />

    <main className="flex-1 w-full max-w-screen-xl px-4 py-16 mx-auto sm:px-8">
      <section className="flex flex-wrap items-center text-center">
        <div className="w-full sm:w-1/2 px-4 mb-8">
          <h1 className="mb-8">Modern IT-specialist for your next project.</h1>
          <h3 className="mb-8">
            We make custom software for calculations and data collection.
          </h3>
          <Button to="/en/contact">Contact Us</Button>
        </div>

        <Astronaut className="w-full sm:w-1/2 px-4 mb-8" />
      </section>

      <section className="flex flex-wrap items-center text-center">
        <Businessman className="w-full sm:w-1/2 px-4 mb-8 order-2 md:order-1" />

        <div className="w-full sm:w-1/2 px-4 mb-8 order-1 md:order-2">
          <h2 className="mb-8">M-Power</h2>
          <h3 className="mb-8">
            M-Power is strategic planning software, a navigator, that gives your
            company realistic income statement projections for the entire
            accounting period.
          </h3>
          <Button to="/en/m-power">Read More</Button>
        </div>
      </section>
    </main>

    <Footer />
  </div>
)

export default IndexPage
